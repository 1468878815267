<template>
  <div class="animated fadeIn">
    <edit-service :type="'dental'" :label="'سرویس دندانپزشکی'" :back="'/admin/dentalServices'"/>
  </div>
</template>
<script>
const EditService = () => import("@/components/services/EditService");

export default {
  components: {
    EditService
  }
};
</script>

